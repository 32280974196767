
import { defineComponent } from 'vue';

import ArrowLeftComponent from '@/component/icon/ArrowLeftIcon.component.vue';

export default defineComponent({
    name: 'PageActionsBarComponent',

    components: {
        ArrowLeftComponent,
    },

    props: {
        returnLink: {
            type: String,
            required: false,
        },
        returnText: {
            type: String,
            required: false,
        },
    },

    setup() {},
});
