
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProjectTagComponent',

    props: {
        tag: {
            type: String,
            required: true,
        },
        frequency: {
            type: Number,
            required: false,
        },
    },

    setup() {},
});
