
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoadingComponent',

    props: {
        message: String,
    },

    setup() {},
});
