import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "project-tag-component" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("small", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.tag), 1),
    (_ctx.frequency)
      ? (_openBlock(), _createElementBlock("strong", _hoisted_2, " (" + _toDisplayString(_ctx.frequency) + ")", 1))
      : _createCommentVNode("", true)
  ]))
}