
import { defineComponent, onMounted, ref } from 'vue';
import Draggable from 'vuedraggable';

import ProjectTagComponent from '@/view/projects/component/ProjectTag.component.vue';
import PageContentComponent from '@/component/layout/PageContent.component.vue';
import PageTitleComponent from '@/component/layout/PageTitle.component.vue';
import PageActionsBarComponent from '@/component/layout/PageActionsBar.component.vue';
import LoadingComponent from '@/component/Loading.component.vue';
import ButtonComponent from '@/component/Button.component.vue';
import UserMessageComponent, { UserMessage } from '@/component/UserMessage.component.vue';

import { projectsSettingsClient } from '@/api/client/projects-settings/ProjectsSettings.client';

interface ProjectsSettings {
    displayOrder: Array<DisplayOrder>;
}

interface DisplayOrder {
    reference: string;
    title: string;
}

export default defineComponent({
    name: 'ProjectSettingsView',

    components: {
        Draggable,
        ProjectTagComponent,
        PageContentComponent,
        PageTitleComponent,
        PageActionsBarComponent,
        LoadingComponent,
        ButtonComponent,
        UserMessageComponent,
    },

    setup() {
        const settings = ref<ProjectsSettings | null>(null);
        const isLoading = ref<boolean>(false);

        const loadUserMessage = ref<UserMessage>(UserMessage.none());
        const displayOrderUserMessage = ref<UserMessage>(UserMessage.none());

        onMounted(async () => {
            isLoading.value = true;
            loadUserMessage.value = UserMessage.none();

            const result = await projectsSettingsClient.getProjectsSettings();
            if (result instanceof Error) {
                isLoading.value = false;
                loadUserMessage.value = UserMessage.error(result.message || 'Unable to load projects settings; please try refreshing the page.');
                return;
            }

            settings.value = {
                displayOrder: result.displayOrder.map(x => ({
                    reference: x.reference,
                    title: x.title,
                })),
            };

            isLoading.value = false;
            loadUserMessage.value = UserMessage.none();
        });

        return {
            settings,
            isLoading,
            loadUserMessage,
            displayOrderUserMessage,

            async onUpdateDisplayOrder() {
                if (settings.value === null)
                    return;

                displayOrderUserMessage.value = UserMessage.none();

                const result = await projectsSettingsClient.updateProjectsSettings({
                    displayOrder: settings.value.displayOrder.map(x => x.reference),
                });
                if (result instanceof Error) {
                    displayOrderUserMessage.value = UserMessage.error(result.message || 'Unable to update projects display order; please try refreshing the page.');
                    return;
                }

                displayOrderUserMessage.value = UserMessage.success('Successfully updated projects display order.');
            },
        }
    },
});
